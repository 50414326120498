import React from 'react';
import water from './assets/water2.mp4';

function First() {
  return (
    <div className='w-screen h-[600px] flex flex-col'>
      <video
        src={water}
        autoPlay
        muted
        loop
        playsInline
        className='w-full h-full object-cover absolute -z-50'
      />
      <div
        className='w-full m-auto sm:w-[800px] text-4xl sm:text-7xl text-center text-white tracking-tighter relative'
        style={{ fontFamily: 'Cormorant Garamond' }}
      >
        A digital destination that elevates <i>brands</i> through <i>talent</i>{' '}
        collaboration & <i>experience</i>
        <div className='w-full text-center text-lg font-sans absolute -bottom-16 tracking-widest'>
          TAKE YOUR BRAND ON HOLIDAY
        </div>
        <div className='w-0.5 h-[200px] bg-white absolute -bottom-80 left-1/2 translate-x-1/2' />
      </div>
    </div>
  );
}

export default First;
