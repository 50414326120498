import React from 'react';
import PalmsGrain from './assets/PalmsGrain.webp';
import Footer from './components/Footer';
import Header from './components/Header';
import First from './First';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';

function Home(props) {
  return (
    <div className='selection:bg-hhpink overflow-x-hidden'>
      <div className='w-screen h-screen relative'>
        <Header />
        <img src={PalmsGrain} alt='background palms' className='w-full h-full object-cover relative -z-10 opacity-80' />
        <div className='absolute left-1/3 top-60 sm:top-1/4 -translate-x-1/2 sm:left-1/4 font-yellowtail text-4xl sm:text-6xl -rotate-12 w-fit text-white'>
          Greetings from
        </div>
        <div className='font-shrikhand absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-7xl sm:text-9xl text-center text-hhpink'>
          <div>Holiday</div>
          <div>House</div>
        </div>
      </div>
      <First />
      <Second />
      {/* <Third /> */}
      <Fourth />
      <Footer />
    </div>
  );
}

export default Home;
