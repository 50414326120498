import React from 'react';

function Fourth() {
  return (
    <div className='flex flex-col w-full bg-hhblue py-20'>
      <div className='w-full text-white sm:text-4xl font-dainty tracking-tighter text-center'>
        Tired of everyday advertising? <i>Us too.</i> <br />
        Wake up on holiday with us — <i>your brand, our talent</i>.
      </div>
      <a href='mailto:kaitlin@holidayhousesocial.com' target='_blank' rel='noreferrer' className='w-fit mx-auto mt-10'>
        <div className='flex flex-col w-[300px] h-[50px] outline-hhpink outline text-center justify-center hover:bg-hhpink text-white hover:text-hhblue duration-150'>
          <p className='font-closer sm:text-2xl'>CONTACT US</p>
        </div>
      </a>
      <p className='font-shrikhand text-white text-2xl sm:text-6xl self-center mt-10 opacity-80 text-center'>
        wish you were here!
      </p>
    </div>
  );
}

export default Fourth;
