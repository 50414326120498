import React from 'react';
import { IoLogoInstagram } from 'react-icons/io';
import { IoMailOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className='absolute right-5 top-5'>
      <div className='flex items-center justify-center text-white gap-2'>
        <a
          href='http://instagram.com/holidayhousesocial'
          target='_blank'
          rel='noreferrer'
        >
          <IoLogoInstagram
            size={50}
            className='opacity-60 hover:opacity-100 transition-all duration-300 hover:text-hhpink'
          />
        </a>
        <a
          href='mailto:kaitlin@holidayhousesocial.com'
          target='_blank'
          rel='noreferrer'
        >
          <IoMailOutline
            size={50}
            className='opacity-60 hover:opacity-100 transition-all duration-300 hover:text-hhpink'
          />
        </a>
      </div>
    </div>
  );
}

export default Header;
