import React from 'react';
import palmbg from './assets/palmbg.webp';

function Second() {
  return (
    <div className='flex w-screen sm:h-screen relative'>
      <div>
        <img
          src={palmbg}
          alt='bg'
          className='w-screen h-full top-1/2 -translate-y-1/2 absolute -z-40 object-cover opacity-75'
        />
      </div>
      <div className='h-full w-full bg-hhpink absolute -z-50' />
      <div className='bg-hhblue/90 flex flex-col sm:w-3/4 p-10 m-10 sm:m-auto justify-center'>
        <p className='text-white text-4xl sm:text-7xl font-closer self-center text-center'>HOLIDAY HOUSE</p>
        <p className='text-white text-lg font-dainty pt-2 tracking-widest uppercase self-center text-center'>
          is a boutique digital talent management company.
        </p>
        <div className='flex flex-col sm:flex-row w-full pt-10 justify-center gap-16'>
          <div className='flex flex-col sm:w-1/3'>
            <p className='font-closer uppercase text-white text-2xl self-center mb-4'>Talent</p>
            <div className='flex self-center outline w-full p-5 outline-hhpink'>
              <p className='font-dainty text-white text-center sm:text-2xl tracking-wide self-center sm:h-[275px]'>
                We manage an exclusive portfolio of top-tier talent spanning across the lifestyle, wellness, fashion,
                and celeb-adjacent verticals. With curation at our core, our management style offers talent the most
                hands on experience.
              </p>
            </div>
          </div>
          <div className='flex flex-col sm:w-1/3'>
            <p className='font-closer uppercase text-white text-2xl self-center mb-4'>Brands</p>
            <div className='flex self-center justify-center outline w-full p-5 outline-hhpink'>
              <p className='font-dainty text-white text-center sm:text-2xl tracking-wide self-center sm:h-[275px]'>
                We collaborate with our brand & agency partners to produce the most successful campaigns by
                understanding their goals and pairing them with the right talent who then bring the vision to life and
                generate results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Second;
