import React from 'react';

function Footer() {
  return (
    <div className='w-full bg-hhpink text-hhblue flex flex-col justify-center items-center py-8'>
      <p className='font-dainty text-2xl italic'>Made in Los Angeles</p>
      <p className='text-sm'>© 2022 Holiday House Social</p>
    </div>
  );
}

export default Footer;
